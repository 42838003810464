export default {
  // api: "http://localhost/api/",
  api: "https://enture.nebeskie.com/api/",
  // streamApi:'http://localhost/stream',
  streamApi: "https://enture.nebeskie.com/stream",
  theme: "dark",
  lang: "english",
  viewsLoaded: false,
  checklistsMakersListLoaded: false,
  checklistsApproversListLoaded: false,
  checklistsCheckersListLoaded: false,
  // checklistsIssuerListLoaded: false,
  manufacturingLogbooksPowerMakersListLoaded: false,
  manufacturingLogbooksMakersListLoaded: false,
  manufacturingLogbooksApproversListLoaded: false,
  manufacturingLogbooksCheckersListLoaded: false,
  // manufacturingLogbooksIssuersListLoaded: false,
  machinesLoaded: false,
  settingMode: false,
  loggedIn: false,
  clientSafety: false,
  jwt: null,
  user: null,
  userData: null,
  organisation: null,
  error: null,
  dashboardView: null,
  deviceTypes: [],
  dataTypes: [],
  tagTypes: [],
  mDeviceTypes: [],
  orgTypes: [],
  countryCodes: [],
  words: [],
  dashboardPanels: [],
  dashboardGauges: [],
  dashboardParams: [],
  dashboardMachines: [],
  views: [],
  entureModules: [],
  areas: [],
  activities: [],
  tasks: [],
  // last7Days:[],
  // last30DaysSNodeData:{},
  UAMUsers: [],
  itemIssuers: [],
  // monthlyData:[],
  workspaces: [],
  processes: [],
  machines: [],
  // groups:[],
  departments: [],
  notificationGroups: [],
  machineGroups: [],
  paramGroups: [],
  userGroups: [],
  devices: [],
  vdevices: [],
  s7Devices: [],
  mqttDevices: [],
  // nodes:[],
  // nodeStates:[],
  schedules: [],
  // snodes:[],
  parameters: [],
  params: [],
  opcParams: [],
  s7Params: [],
  mqttParameters: [],
  computedParameters: [],
  manualParameters: [],
  derivedParameters: [],
  writeParams: [],
  comboParams: [],
  calculatedParams: [],
  reports: [],
  secReports: [],
  // granularSECReports:[],
  secTimeFrameReports: [],
  consolidatedReports: [],
  dynamicExcelReports: [],
  excelTemplateBasedReports: [],
  energyAnalysisReports:[],
  usageReports: [],
  energyUsageReports: [],
  energyReports: [],
  energyDistributionReports: [],
  energyBillDistReports: [],
  actions: [],
  sheets: [],
  targets: [],
  tariffs: [],
  skus: [],
  products: [],
  locations: [],
  locationEventTypes: [],
  roles: [],
  checklistMakersList: [],
  checklistsApproverLists: [],
  checklistsCheckersList: [],
  // checklistsIssuerList: [],
  manufacturingLogbooksMakersList: [],
  manufacturingLogbooksPowerMakersList: [],
  manufacturingLogbooksApproverLists: [],
  manufacturingLogbooksCheckersList: [],
  // manufacturingLogbooksIssuerList: [],
  actionMapping: [],
  triggers: [],
  paramTriggerMapping: [],
  paramTargetMapping: [],
  productionLogs: [],
  dataLogs: [],
  capacityLogs: [],
  dynamicLogs: [],
  batchProductionLogs: [],
  shiftMachineUsageLogs: [],
  lists: [],
  dependentLists: [],
  dependentListData: [],
  listData: [],
  shifts: [],
  shiftData: [],
  targetData: [],
  tariffData: [],
  currentSNodeData: {},
  currentParameterData: {},
  currentDerivedParameterData: {},
  currentSnodeUsage: {},
  currentComputedParamUsage: {},
  mGates: [],
  hubs: [],
  notifications: [],
  maintainedOrgs: [],
  engineerAccessLevel: 10,
  betaAccessLevel: 12,
  developerAccessLevel: 14,
  userMenuLinks: [
    {
      title: "user_access",
      caption: "User Data",
      icon: "shield-account-outline",
      levels: ["uam"],
      // levels:['root'],
      link: "/user",
    },
    {
      title: "profile",
      caption: "User Profile",
      icon: "account-outline",
      levels: [],
      // levels:['root'],
      link: "/profile",
    },
    {
      title: "admin",
      caption: "Enture Admin",
      icon: "security",
      levels: ["engineeraccess", "betaaccess"],
      // levels:['root'],
      link: "/entureAdmin",
    },
  ],
  organisationMenuLinks: [
    {
      title: "organisation_details",
      caption: "Organisation Details",
      icon: "city-variant-outline",
      levels: [],
      // levels:['root'],
      link: "/organisationdetails",
    },
  ],
  settingsMenuLinks: [
    {
      title: "switch_organisation",
      caption: "Switch Organisation",
      icon: "home-switch-outline",
      levels: ["maintainer"],
      // levels:['root'],
      link: "/switchorganisation",
    },
    {
      title: "create_new_organisation",
      caption: "Create Organisation",
      icon: "plus-circle-outline",
      levels: ["engineeraccess"],
      // levels:['root'],
      link: "/createorganisation",
    },
    {
      title: "login",
      caption: "Change Organisation",
      icon: "car-shift-pattern",
      levels: ["engineeraccess", "alphaaccess"],
      // levels:['root'],
      link: "/changeorganisation",
    },
    {
      title: "add_oem_logo",
      caption: "Add OEM Logo",
      icon: "mdi mdi-golf-tee",
      levels: ["engineeraccess"],
      // levels:['root'],
      link: "/addoemlogo",
    },
  ],
  configurationMenuLinks: [
    {
      title: "devices",
      caption: "All the devices (Engineer view)",
      icon: "fan",
      levels: ["device"],
      link: "/devices",
    },
    {
      title: "derived_data_units",
      caption: "See your derived data",
      icon: "chart-sankey-variant",
      levels: ["engineeraccess"],
      link: "/derived",
    },
    {
      title: "roles",
      caption: "Create Roles",
      icon: "shield-lock-outline",
      levels: ["engineeraccess", "betaaccess"],
      link: "/roles",
    },
    {
      title: "engineering",
      caption: "Engineering Panel",
      icon: "account-hard-hat-outline", //'fan-alert',
      levels: ["engineeraccess", "betaaccess"],
      link: "/engineeringPanel",
    },
    {
      title: "control_panel",
      caption: "Admin section",
      icon: "wrench-outline",
      levels: ["device", "network"],
      link: "/controlpanel",
    },
  ],
  menuLinks: [
    {
      title: "dashboard_heading",
      caption: "dashboard info",
      icon: "view-dashboard-outline",
      levels: [],
      link: "/dashboard",
    },

    {
      title: "views",
      caption: "View wise monitoring",
      icon: "view-carousel-outline",
      levels: ["device"],
      link: "/views",
    },
    {
      title: "workspaces",
      caption: "View all machines in workspace",
      icon: "folder-outline",
      levels: ["device"],
      link: "/machines",
    },
    {
      title: "Applications",
      caption: "View all applcations",
      icon: "application-outline",
      levels: ["betaaccess"],
      link: "/applications",
    },
    {
      title: "reports_analysis",
      caption: "See your energy reports",
      icon: "chart-line",
      levels: ["report"],
      link: "/reports",
    },
    {
      title: "actions_triggers",
      caption: "Alarms & Triggers",
      icon: "alarm-light-outline", //'fan-alert',
      levels: ["scheduling"],
      link: "/alarms",
    },
    {
      title: "modules",
      caption: "Create and Monitor Enture Modules",
      icon: "view-module-outline",
      levels: ["device"],
      link: "/modules",
    },
    {
      title: "processes",
      caption: "View all processes",
      icon: "dots-square",
      levels: ["device"],
      link: "/processes",
    },
    {
      title: "air_quality",
      caption: "Air Quality",
      icon: "weather-windy",
      levels: ["device"],
      link: "/airquality",
    },
    {
      title: "manufacturing",
      caption: "Manufacturing",
      icon: "multicast",
      levels: [],
      link: "/manufacturing/checklist",
    },
    {
      title: "esg",
      caption: "ESG",
      icon: "spa-outline",
      levels: ["logs", "parameter"],
      link: "/esg",
    },
    // {
    //   title: 'GHG Emissions',
    //   caption: 'GHG Emissions',
    //   icon: 'leaf-maple',
    //   levels:['logs'],
    //   link: '/ghg'
    // },
    {
      title: "task_managment",
      caption: "Activity & Task Managment",
      icon: "calendar-check-outline",
      levels: ["betaaccess"],
      link: "/taskmanagment",
    },
    {
      title: "oee",
      caption: "OEE",
      icon: "sprout-outline",
      levels: ["betaaccess"],
      link: "/oee",
    },
    // {
    //   title: 'sustainability',
    //   caption: 'Sustainability Details',
    //   icon: 'sprout-outline',
    //   levels:['betaaccess'],
    //   link: '/sustainability'
    // },
    {
      title: "groups",
      caption: "Create and control group of devices",
      icon: "group",
      levels: [],
      link: "/groups",
    },

    // {
    //   title: 'Derived',
    //   caption: 'See your derived data',
    //   icon: 'chart-sankey-variant',
    //   levels:['engineeraccess'],
    //   link: '/derived'
    // },
    // {
    //   title: 'Devices',
    //   caption: 'All the devices (Engineer view)',
    //   icon: 'fan',
    //   levels:['device'],
    //   link: '/devices'
    // },

    // {
    //   title: 'Engineering',
    //   caption: 'Engineering Panel',
    //   icon: 'account-hard-hat-outline', //'fan-alert',
    //   levels:['engineeraccess','betaaccess'],
    //   link: '/engineeringPanel'
    // },
    {
      title: "log_book",
      caption: "Industry Logs",
      icon: "text-box-outline",
      levels: ["logs"],
      link: "/logbook",
    },
    {
      title: "monitor",
      caption: "Monitor all the devices",
      icon: "altimeter",
      levels: ["device"],
      link: "/monitor",
    },
    // {
    //   title: 'Sld Shapes',
    //   caption: 'Single Line Diagram',
    //   icon: 'altimeter',
    //   levels:['alphaaccess'],
    //   link: '/sldShapes'
    // },
    /*{
      title: 'Ebill Data',
      caption: 'Ebill Data',
      icon: 'battlenet',
      levels:['ebill'],
      link: '/data'
    },*/
    // {
    //   title: 'User Access',
    //   caption: 'User Data',
    //   icon: 'account',
    //   levels: ['uam'],
    //   // levels:['root'],
    //   link: '/user'
    // },
    // {
    //   title: 'Control Panel',
    //   caption: 'Admin section',
    //   icon: 'wrench',
    //   levels:['device','network'],
    //   link: '/controlpanel'
    // },

    // {
    //   title: 'Settings',
    //   caption: 'Your settings',
    //   icon: 'cog',
    //   levels:[],
    //   link: '/settings'
    // },
    /*{
      title: 'Help',
      caption: 'Help',
      icon: 'help',
      levels: ['engineeraccess'],
      link: '/Help'
    }*/
  ],
};
